.trip-section {
  padding: 5rem 0;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.5) 75%, #000 100%), url("../../../assets/img/background/sommerfreizeit.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
}

.dot-logo {
  height: 5rem;
  width: 5rem;
  background-color: var(--logo-yellow);
  border-radius: 50%;
  display: inline-block;
}

.logo {
  margin: auto;
  width: 100%;
}