  
/* The browser window */
.container_browser {
  border: 3px solid #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  max-width: 30rem;
  width: 100%;
  margin: auto;
}

/* Container for columns and the top "toolbar" */
.row_browser {
  padding: 10px;
  background: #f1f1f1;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

/* Create three unequal columns that floats next to each other */
.column_browser {
  float: left;
}

.left_browser {
  width: 15%;
}

.right_browser {
  width: 10%;
}

.middle_browser {
  width: 75%;
}

/* Clear floats after the columns */
.row_browser:after {
  content: "";
  display: table;
  clear: both;
}

/* Three dots */
.dot_browser {
  margin-top: 4px;
  height: 12px;
  width: 12px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}

/* Style the input field */
input[type=text] {
  width: 100%;
  border-radius: 3px;
  border: none;
  background-color: white;
  margin-top: -8px;
  height: 25px;
  color: #666;
  padding: 5px;
}

/* Three bars (hamburger menu) */
.bar_browser {
  width: 17px;
  height: 3px;
  background-color: #aaa;
  margin: 3px 0;
  display: block;
}

/* Page content */
.content_browser {
  padding: 10px;
}