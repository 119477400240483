
  #navHome {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
    transition: background-color 0.2s ease;
  }
  #navHome .navbar-brand {
    font-family: 'Quicksand', "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #212529;
  }
  #navHome .navbar-nav .nav-item .nav-link {
    color: #6c757d;
    font-family: 'Quicksand', "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 0.9rem;
    padding: 0.75rem 0;
  }
  #navHome .navbar-nav .nav-item .nav-link:hover, #navHome .navbar-nav .nav-item .nav-link:active {
    color: var(--logo-blue);
  }
  #navHome .navbar-nav .nav-item .nav-link.active {
    color: var(--logo-blue);
  }
  @media (min-width: 992px) {
    #navHome {
      box-shadow: none;
      background-color: transparent;
    }
    #navHome .navbar-brand {
      color: rgba(255, 255, 255, 0.7);
    }
    #navHome .navbar-brand:hover {
      color: #fff;
    }
    #navHome .navbar-nav .nav-item .nav-link {
      color: rgba(255, 255, 255, 0.7);
      padding: 0 1rem;
    }
    #navHome .navbar-nav .nav-item .nav-link:hover {
      color: #fff;
    }
    #navHome .navbar-nav .nav-item:last-child .nav-link {
      padding-right: 0;
    }
    #navHome.navbar-shrink {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      background-color: #fff;
    }
    #navHome.navbar-shrink .navbar-brand {
      color: #212529;
    }
    #navHome.navbar-shrink .navbar-brand:hover {
      color: var(--logo-blue);
    }
    #navHome.navbar-shrink .navbar-nav .nav-item .nav-link {
      color: #212529;
    }
    #navHome.navbar-shrink .navbar-nav .nav-item .nav-link:hover {
      color: var(--logo-blue);
    }
  }
  
  #navPage {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    background-color: #fff;
    transition: background-color 0.2s ease;
  }
  
  #navPage .navbar-brand {
    font-family: 'Quicksand', "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    color: #212529;
  }
  #navPage .navbar-nav .nav-item .nav-link {
    color: #6c757d;
    font-family: 'Quicksand', "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-weight: 700;
    font-size: 0.9rem;
    padding: 0.75rem 0;
  }
  #navPage .navbar-nav .nav-item .nav-link:hover, #navPage .navbar-nav .nav-item .nav-link:active {
    color: var(--logo-blue);
  }
  #navPage .navbar-nav .nav-item .nav-link.active {
    color: var(--logo-blue) !important;
  }
  
  @media (min-width: 992px) {
    
    #navPage .navbar-brand {
      color: rgba(255, 255, 255, 0.7);
    }
    #navPage .navbar-brand:hover {
      color: var(--logo-blue);
    }
    #navPage .navbar-nav .nav-item .nav-link {
      color: #000;
      padding: 0 1rem;
    }
    #navPage .navbar-nav .nav-item .nav-link:hover {
      color: var(--logo-blue);
    }
    #navPage .navbar-nav .nav-item:last-child .nav-link {
      padding-right: 0;
    }
    #navPage.navbar-shrink {
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
      background-color: #fff;
    }
    #navPage.navbar-shrink .navbar-brand {
      color: #212529;
    }
    #navPage.navbar-shrink .navbar-brand:hover {
      color: var(--logo-blue);
    }
    #navPage.navbar-shrink .navbar-nav .nav-item .nav-link {
      color: #212529;
    }
    #navPage.navbar-shrink .navbar-nav .nav-item .nav-link:hover {
      color: var(--logo-blue);
    }
  }