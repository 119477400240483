.br-1 {
	border-radius: 0.25rem;
}

div.image_container {
	width: 20%;
	overflow: hidden;
}

.card-img-left {
	width: 100%;
	height: 100%;
	border-top-left-radius: calc(0.25rem - 1px);
  	border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-footer-1 {
    padding: 0.5rem 1rem;
    background-color: rgba(0, 0, 0, 0.03);
    border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer-1:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.row-gutter {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
}

.mh-20 {
	min-height: 10rem;
}