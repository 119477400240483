/* footer */

.footer-container {
    padding: 2rem 0;
  }
  
  /* Clear floats after the columns */
  .footer-row:after {
    content: "";
    display: table;
    clear: both;
  }
  
  .footer-column {
    float: left;
    margin: 0 0.5rem;
  }
  
  .left {
    width: 46%;
  }
  
  .middle, .right {
    width: 24%;
  }
  
  .link-footer {
    text-decoration: none;
  }
  
  p.footer {
    padding: 0.5rem 1rem 0.5rem 0;
  }
  
  ul.footer {
    margin: 0;
    padding: 0;
    display: block;
    list-style-type: none;
  }
  
  a.link-color {
    color: var(--logo-white);
  }
  
  a.link-color:hover {
    color: var(--logo-yellow);
  }
  
  .footer-socials {
  margin-top: 0.5rem;
  }
  
  .social-links {
    padding: 0.2rem;
  }
  
  .social-icon {
    color: var(--logo-white);
    padding: 0 0.2rem;
  }
  
  .social-icon:hover {
    color: var(--logo-yellow);
  }
  
  .mail {
    font-size: calc(1.5rem + 1.5vw) !important;
  }
  
  .text-left{
    float: left;
  }
  
  .text-right {
    float: right;
  }
  
  
  /* Responsive layout */
  @media (max-width: 576px) {
    .footer-column {
      width: 100%;
      float: left;
      margin: 0 0.5rem;
    }
  
    .text-right {
      float: left;
    }
  }
  
  @media (min-width: 768px) {
    
  }
  @media (max-width: 992px) {
    .footer-column {
      float: left;
      margin: 0 0.5rem;
    }
  }