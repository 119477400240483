.galleryRow {
  display: -ms-flexbox; /* IE 10 */
  display: flex;
  -ms-flex-wrap: wrap; /* IE 10 */
  flex-wrap: wrap;
  padding: 0 4px;
}

.column {
  -ms-flex: 50%; /* IE 10 */
  flex: 1 1 50%;
  padding: 0 4px;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
}

.gallery-img {
  width: 100%;
} 

@media (min-width: 768px) {
  .column {
    -ms-flex: 25%; /* IE 10 */
    flex: 1 1 25%;
    padding: 0 4px;
  }
}

@media (min-width: 1400px) {
  .mw-xxl {
    max-width: 1400px;
  }
}
