hr.divider-trips {
    height: 0.2rem;
    max-width: 100%;
    margin: 1.5rem auto;
    background-color: var(--logo-blue);
    opacity: 1;
  }

@media (min-width: 768px) {
    .text-center-trip {
        text-align: left;
    }
}